import { FC } from 'react';

import { Box, css, styled } from '@mui/material';

import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { FormInputField } from 'components/Forms';
import FormDateField from 'components/Forms/FormDateField';
import { AdvancedSearchFormFields } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';
import { FilledButton, OutlinedButton, TextButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  onClose: () => void;
  onSearch: (formValues: AdvancedSearchFormFields) => void;
  onClearAll: () => void;
  defaultValues: AdvancedSearchFormFields;
  numberOfRows: 1 | 2;
}

export const AdvancedSearchMenu: FC<Props> = ({
  onClose,
  onSearch,
  onClearAll,
  defaultValues,
  numberOfRows
}) => {
  const methods = useForm<AdvancedSearchFormFields>({
    defaultValues
  });

  const { reset, control, handleSubmit, formState } = methods;

  const formValues = useWatch({ control });
  const isSaveDisabled =
    (!formValues.mrn && !formValues.dob && !formValues.name && !formValues.phone) ||
    formState.isSubmitting;

  return (
    <FormProvider {...methods}>
      <StyledAdvancedSearch>
        <Text
          variant="body2"
          color="secondary"
          textTransform="uppercase"
          mb={20}
          display="inline-block"
        >
          Advanced Search
        </Text>

        <StyledClearAllButtonContainer>
          <TextButton
            variant="secondary"
            onClick={() => {
              reset({ name: '', dob: '', mrn: '', phone: '' });
              onClearAll();
            }}
          >
            Clear All
          </TextButton>
        </StyledClearAllButtonContainer>

        <Box display="flex" flexWrap="wrap" gap={20} mb={36}>
          <Box flex={numberOfRows === 2 ? '1 1 40%' : 1}>
            <FormInputField name="name" label="Patient Name" placeholder={null} />
          </Box>

          <Box flex={numberOfRows === 2 ? '1 1 40%' : 1}>
            <FormInputField name="mrn" label="MRN" placeholder={null} />
          </Box>

          <Box flex={numberOfRows === 2 ? '1 1 40%' : 1}>
            <FormDateField label="Date of Birth" name="dob" />
          </Box>

          <Box flex={numberOfRows === 2 ? '1 1 40%' : 1}>
            <FormInputField type="number" name="phone" label="Phone" placeholder={null} />
          </Box>
        </Box>

        <Box display="flex" alignSelf="flex-end" alignItems="center">
          <OutlinedButton size="medium" onClick={onClose} mr={20}>
            Cancel
          </OutlinedButton>

          <FilledButton disabled={isSaveDisabled} onClick={handleSubmit(onSearch)}>
            Search
          </FilledButton>
        </Box>
      </StyledAdvancedSearch>
    </FormProvider>
  );
};

const StyledAdvancedSearch = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 700px;
    padding: ${theme.spacing(20)};
    background-color: ${theme.palette.natural.white};
    border-radius: ${theme.borderRadius.xSmall};
    box-shadow: ${theme.boxShadow.common};
    position: relative;
  `
);

const StyledClearAllButtonContainer = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
