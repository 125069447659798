import { FunctionComponent } from 'react';

import { Typography } from '@mui/material';
import { observer } from 'mobx-react';

import OperatorTicket, { OperatorTicketSource } from 'models/OperatorTicket';

import Ticket from 'models/Ticket';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';

import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';

import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  descriptionContainerOptions: { maxNumberOfLines?: number };
  descriptionRef?: (node: HTMLElement) => void;
  subTypeNode?: TicketTypeNode;
  isCallLoggerVersion?: boolean;
}

export const OperatorTicketSummary: FunctionComponent<Props> = observer(
  ({ ticket, subTypeNode, descriptionContainerOptions, descriptionRef, isCallLoggerVersion }) => {
    const providerLocationText = useTicketProviderLocation(ticket);
    const operatorTicket = ticket.operatorTicket as OperatorTicket;
    const getTitleText = () => (
      <div>
        <Text variant={isCallLoggerVersion ? 'body1' : 'h4'}>{subTypeNode?.fullName}</Text>
        {providerLocationText && (
          <Text variant={isCallLoggerVersion ? 'body2' : 'form-text'} color="secondary">
            {' '}
            — {providerLocationText}
          </Text>
        )}
      </div>
    );

    const hasPatientOperatorTicketSummaryPrefix =
      operatorTicket.source === OperatorTicketSource.Patient && Boolean(operatorTicket.notes);
    return (
      <>
        {getTitleText()}

        <Text
          variant="body2"
          {...descriptionContainerOptions}
          component="div"
          ref={descriptionRef}
          wordBreak="break-word"
        >
          {hasPatientOperatorTicketSummaryPrefix && (
            <Typography variant="body1">Patient Request via App:</Typography>
          )}{' '}
          <HtmlContent>{operatorTicket.notes}</HtmlContent>
        </Text>
      </>
    );
  }
);
