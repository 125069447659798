import { ErrorName, transformErrorUiProps, UserInfoInUseFailure } from 'errors';

import { GlobalSearchPatientsResponse } from 'fetchers/responses/patient.response';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { CallsParser } from 'parsers/CallsParser';
import ParseServerResponseService from 'parsers/ParseServerResponseService';

import PatientParser from 'parsers/PatientParser';

import Patient, { IPatientContact, IPatientUpdateableFields, PatientSex } from 'models/Patient';
import ScheduledProtocol from 'models/ScheduledProtocol';
import Ticket from 'models/Ticket';

import { IOptOutSubmitObject } from 'views/Modals/OptOutModal';

import { GLOBAL_PATIENTS_SEARCH_LIMIT } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.constants';

export interface CreateAndInvitePatientBody extends PatientRequestBaseProperties {
  activateProtocol: boolean;
  protocol: ScheduledProtocol;
  status: string;
  sourceId: number;
  emrPatientId: string;
  inviteTimeInSeconds: number;
}

export interface UpdateAndInvitePatientBody extends PatientRequestBaseProperties {
  activateProtocol: boolean;
  patientId: number;
  protocol: ScheduledProtocol;
}

interface AwaitingActivationPatientsQuery {
  pageNumber: number;
  recordsPerPage: number;
  sortBy: string;
  sortAsc: boolean;
}

export interface SearchedPatient {
  mrn: string;
  firstName: string;
  lastName: string;
  patientId: number;
  dob: string;
  emrPatientId: string | null;
  userId: number;
  phone: string;
}

export interface SearchedEmrPatient {
  phoneNumber: string;
  locationId: number;
  gender: PatientSex;
  mrn: string;
  providerId: string;
  emrPatientId: string;
  firstName: string;
  lastName: string;
  dob: string;
}

export interface SearchedPatientQuery {
  searchTerm?: string;
  limit?: number;
  includeSelf?: boolean;
}

export interface AdvancedSearchPatientsQuery {
  name?: string;
  mrn?: string;
  dob?: string;
  limit?: number;
  includeSelf?: boolean;
}

export interface PatientRequestBaseProperties {
  firstName: string;
  lastName: string;
  phoneNum: string;
  phoneType: string;
  sex: PatientSex;
  dateOfBirth: string | null;
  remoteMonitoringConsent: Date;
  copayConsentGiven: Date;
  patientReadTerms: Date;
  mrn: string;
  locale: string;
  patientTagIds: number[];
  optOutInfo: IOptOutSubmitObject | null;
  locationId: number | null;
  enrollmentStatus: string;
  financialAssistance: boolean;
  providerId: string;
}

export function getPatientRequestBaseProperties(
  patient: Patient | IPatientUpdateableFields
): PatientRequestBaseProperties {
  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    phoneNum: patient.phone,
    phoneType: patient.phoneType,
    sex: patient.sex,
    dateOfBirth: patient.dateOfBirth || null, // Make sure we don't send an empty string,
    remoteMonitoringConsent: patient.remoteMonitoringConsent,
    copayConsentGiven: patient.copayConsentGiven,
    patientReadTerms: patient.patientReadTerms,
    mrn: patient.mrn,
    locale: patient.locale,
    patientTagIds: patient.tags,
    optOutInfo: patient.optOut ? patient.optOut.requestFields : null,
    locationId: patient.location ? patient.location.id : null,
    enrollmentStatus: patient.enrollmentStatus,
    financialAssistance: patient.financialAssistance,
    providerId: patient.providerId
  };
}

export function getPatientUpdateBody(
  patientId: number,
  updateableFields: IPatientUpdateableFields,
  activateProtocol: boolean
) {
  const basePatientProperties = getPatientRequestBaseProperties(updateableFields);
  return {
    ...basePatientProperties,
    protocol: updateableFields.protocol,
    patientId,
    activateProtocol
  };
}

interface PatientResponse {
  calls: any[];
  patient: any;
  questionnairesAnswers: any[];
}

interface PatientWithTicketsResponse extends PatientResponse {
  operatorSymptomsTickets: any[];
}

interface CreateNewPatientBody {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
}

interface MergePatientsBody {
  localPatientId: number;
  targetPatientId: number;
}

const httpService = new HttpService('Patients');

class PatientsFetcher {
  static async loadPatient(patientId: number): Promise<{ patient: Patient; tickets: Ticket[] }> {
    const result = await httpService.get<PatientWithTicketsResponse>({
      url: API_URLS.PATIENT(patientId)
    });
    const tickets = result.operatorSymptomsTickets;
    const patient = PatientParser.parsePatient(result.patient);
    patient.calls = result.calls ? result.calls.map(CallsParser.parseCall) : [];
    return { tickets, patient };
  }

  static async updatePatient(
    patientId: number,
    updateableFields: IPatientUpdateableFields,
    activateProtocol: boolean
  ): Promise<void> {
    return await httpService.put({
      url: API_URLS.UPDATE_PATIENT(patientId),
      data: getPatientUpdateBody(patientId, updateableFields, activateProtocol)
    });
  }

  static async invitePatient(requestData: CreateAndInvitePatientBody | UpdateAndInvitePatientBody) {
    return await httpService.post({
      url: API_URLS.INVITE_PATIENT,
      data: requestData,
      transformError: (error) => {
        if (error.name === ErrorName.MrnInUse) {
          const serverData = error.httpFailure.serverData as UserInfoInUseFailure;
          error.ui.description = `${serverData.patientName} is using this MRN. Please use a different MRN.`;
        }

        if (error.name === ErrorName.UserBlockSendingSms) {
          error.ui.title = 'Patient Invited, but SMS Failed';
          error.ui.description =
            'While the patient was successfully invited / updated, the explanatory Invite SMS failed to send. This occurs when a patient has replied STOP to an SMS message from Canopy. To re-subscribe, the patient can text START to the same number.';
          return error;
        }

        return error;
      }
    });
  }

  static async activatePatient(patientId: number): Promise<boolean> {
    return await httpService.get({
      url: API_URLS.ACTIVATE_PATIENT(patientId),
      transformError: transformErrorUiProps('Failed to activate patient')
    });
  }

  static async updateStartOfCycle(questionnaireId: number, date: Date): Promise<void> {
    return await httpService.post({
      url: API_URLS.UPDATE_CYCLE(questionnaireId),
      data: { cycleStartDate: date },
      transformError: transformErrorUiProps('Fail to update start of cycle')
    });
  }

  static async addContact(patientId: number, contact: IPatientContact): Promise<IPatientContact> {
    return await httpService.post({
      url: API_URLS.ADD_PATIENT_CONTACT(patientId),
      data: { contact },
      transformResponse: ParseServerResponseService.parsePatientContact,
      transformError: transformErrorUiProps('Failed to create contact for patient')
    });
  }

  static async updateContact(
    patientId: number,
    contactId: number,
    contact: IPatientContact
  ): Promise<IPatientContact> {
    return await httpService.put({
      url: API_URLS.UPDATE_PATIENT_CONTACT(patientId, contactId),
      data: { contact },
      transformResponse: ParseServerResponseService.parsePatientContact,
      transformError: transformErrorUiProps('Failed to update contact for patient')
    });
  }

  static async deleteContact(patientId: number, contactId: number): Promise<void> {
    return await httpService.delete({
      url: API_URLS.DELETE_PATIENT_CONTACT(patientId, contactId),
      transformError: transformErrorUiProps('Failed to delete contact for patient')
    });
  }

  static async createPatientFromEmr(originalEmrId: string): Promise<number> {
    return await httpService.post({
      url: API_URLS.CREATE_PATIENT_FROM_EMR,
      data: { originalEmrId },
      transformError: transformErrorUiProps(
        'Failed to create patient from EMR',
        "It seems like this patient doesn't exist in the system. Please refresh and try again."
      )
    });
  }

  static async fetchAwaitingActivationPatients(data: AwaitingActivationPatientsQuery) {
    return await httpService.post<{ currentPage: number; totalPages: number; patients: Patient[] }>(
      {
        url: API_URLS.AWAITING_ACTIVATION_PATIENTS,
        data,
        transformResponse: (response) => ({
          currentPage: response.currentPage,
          totalPages: response.totalPages,
          patients: response.patients.map(PatientParser.parsePatient)
        })
      }
    );
  }

  static async searchGlobalPatients({
    searchTerm,
    limit = GLOBAL_PATIENTS_SEARCH_LIMIT,
    includeSelf = false
  }: SearchedPatientQuery): Promise<GlobalSearchPatientsResponse> {
    return await httpService.get({
      url: API_URLS.SEARCH_GLOBAL_PATIENTS,
      query: {
        searchTerm,
        limit,
        includeSelf
      },
      transformError: transformErrorUiProps('Failed to search global patients')
    });
  }

  static async searchGlobalPatientsAndParse({
    searchTerm,
    limit,
    includeSelf = false
  }: SearchedPatientQuery): Promise<{ patients: Patient[]; hasMore: boolean }> {
    return await httpService.get({
      url: API_URLS.SEARCH_GLOBAL_PATIENTS,
      query: {
        searchTerm,
        limit,
        includeSelf
      },
      transformError: transformErrorUiProps('Failed to search global patients'),
      transformResponse: PatientParser.parsePatientSearchResult
    });
  }

  static async searchPatients({
    searchTerm,
    limit
  }: SearchedPatientQuery): Promise<SearchedPatient[]> {
    return await httpService.get({
      url: API_URLS.SEARCH_PATIENTS,
      query: {
        searchTerm,
        limit
      },
      transformError: transformErrorUiProps('Failed to search patients')
    });
  }

  static async advancedSearchGlobalPatients({
    includeSelf = false,
    limit = GLOBAL_PATIENTS_SEARCH_LIMIT,
    ...rest
  }: AdvancedSearchPatientsQuery): Promise<GlobalSearchPatientsResponse> {
    return await httpService.get({
      url: API_URLS.ADVANCED_SEARCH_PATIENTS,
      query: {
        includeSelf,
        limit,
        ...rest
      },
      transformError: transformErrorUiProps('Failed to search patients')
    });
  }

  static async fetchEmrPatient(emrPatientId: string): Promise<Patient> {
    return await httpService.get({
      url: API_URLS.EMR_PATIENT(emrPatientId),
      transformError: transformErrorUiProps('Failed to search patients'),
      transformResponse: PatientParser.parseEmrSearchedPatient
    });
  }

  static async registerPatient(body: CreateNewPatientBody): Promise<{ patientId: number }> {
    return await httpService.post({
      url: API_URLS.REGISTER_PATIENT,
      data: body
    });
  }

  static async mergePatients(body: MergePatientsBody) {
    return await httpService.post({
      url: API_URLS.MERGE_PATIENTS,
      data: body
    });
  }

  static async hidePatient(patientId: number) {
    return await httpService.post({
      url: API_URLS.HIDE_PATIENT,
      data: { patientId }
    });
  }
}

export default PatientsFetcher;
