// @ts-strict-ignore
import { FC } from 'react';

import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import classNames from 'classnames';

import { flow, map, orderBy, uniqBy } from 'lodash/fp';
import { observer } from 'mobx-react';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';
import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import FemaleAvatarSrc from 'img/avatars/female_avatar.svg';
import MaleAvatarSrc from 'img/avatars/male_avater.svg';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

import { Text } from 'components/UIkit/atoms/Text';

import { SaveDraftButton, SaveDraftMenu } from './CallLoggingSaveDraft';

import './CallLoggingHeader.scss';

interface CallLoggingHeaderProps {
  patient: Patient;
  minimized: boolean;
  onCloseClicked: () => void;
  onSaveDraftClicked: (isCallAttempt?: boolean) => void;
  onToggleMinimizeClicked: () => void;
  isSaveInProgress: boolean;
  isSummaryManuallyEdited: boolean;
}

const Header: FC<CallLoggingHeaderProps> = ({
  patient,
  onCloseClicked,
  isSaveInProgress,
  isSummaryManuallyEdited,
  minimized,
  onSaveDraftClicked,
  onToggleMinimizeClicked
}) => {
  const { ticketTypesStore, ticketsStore, callLoggingStore } = useStores();
  const { currentCall } = callLoggingStore;

  const getCallbackText = () => {
    const { ticketsMap } = ticketsStore;
    // ticket may be resolved while call logging is open, we must check ticket still exists
    const ticketIds = Array.from(currentCall.ticketIds).filter((ticketId) =>
      ticketsMap.get(ticketId)
    );
    if (ticketIds.length === 0) {
      return (
        <>
          <Text variant="body2" color="secondary" mr={4}>
            Contact Patient:
          </Text>
          <Text variant="body2">{getFormattedPhoneNumber(patient.phone)}</Text>
        </>
      );
    }

    if (ticketIds.length === 1) {
      return getCallbackTextForSingleTicket(ticketIds[0]);
    }

    const tickets = getSortedTicketsForCallbackText(ticketIds);

    if (tickets.length === 1 && tickets[0]?.id) {
      return getCallbackTextForSingleTicket(tickets[0].id);
    }

    return (
      <ul>
        {ticketIds.map((ticketId) => (
          <li key={ticketId}>{getCallbackTextForSingleTicket(ticketId, true)}</li>
        ))}
      </ul>
    );
  };

  const getSortedTicketsForCallbackText = (ticketIds: number[]) =>
    flow(
      uniqBy<number>((id) => {
        const ticket = ticketsStore.ticketsMap.get(id);
        return ticket.operatorTicket?.patientContactId;
      }),
      map(ticketsStore.ticketsMap.get),
      orderBy(['createdAt'], ['desc'])
    )(ticketIds) as Ticket[];

  const getCallbackTextForSingleTicket = (ticketId: number, withTicketData: boolean = false) => {
    const ticket = ticketsStore.ticketsMap.get(ticketId);
    if (ticket.isOperatorTicket) {
      let ticketName;
      if (ticket.isSymptomOperatorTicket) {
        ticketName = 'Symptom Report via Triage Call –';
      } else {
        const [subTypeId] = ticket.operatorTicket.subTicketTypeIds;
        const subTypeNode = ticketTypesStore.getOperatorTicketTypeById(subTypeId);
        ticketName = `${subTypeNode.fullName} –`;
      }
      const textStart = withTicketData ? `${ticketName}` : 'Contact';
      if (
        ticket.operatorTicket.patientContactId &&
        ticket.operatorTicket.patientContactId !== ticket.patientId
      ) {
        const patientContact = patient.getContactById(ticket.operatorTicket.patientContactId);
        return (
          <>
            <Text variant="body2" color="secondary" mr={4}>
              {textStart} {patientContact.name} ({patientContact.relationship}):
            </Text>
            <Text variant="body2">{getFormattedPhoneNumber(patientContact.phoneNumber)}</Text>
          </>
        );
      } else {
        return (
          <>
            <Text mr={4} variant="body2" color="secondary">
              {textStart} Patient:
            </Text>
            <Text variant="body2">{getFormattedPhoneNumber(patient.phone)}</Text>
          </>
        );
      }
    } else {
      return (
        <>
          <Text variant="body2" color="secondary" mr={4}>
            {withTicketData ? 'Patient Report –' : 'Contact'} Patient:
          </Text>
          <Text variant="body2">{getFormattedPhoneNumber(patient.phone)}</Text>
        </>
      );
    }
  };

  const renderSaveDraftMenu = currentCall.ticketIds.size === 1;

  const isActive = callLoggingStore.isTimerActive();
  return (
    <div
      className={classNames('window-header', {
        minimized: minimized,
        active: isActive
      })}
    >
      <img
        src={patient.isFemale ? FemaleAvatarSrc : MaleAvatarSrc}
        alt="patient avatar"
        className="header-avatar"
      />
      <div className="header-details-container">
        <Text>
          <Text mr={8} variant="h4" color={minimized && isActive ? 'white' : 'primary'}>
            {patient.fullName}
          </Text>
          {!minimized && (
            <Text color="secondary" variant="form-text">
              {patient.mrn}
            </Text>
          )}
        </Text>

        {!minimized && (
          <Text lineHeight="20px">
            <Text mr={4} variant="body2" color="secondary">
              DOB:
            </Text>
            <Text variant="body2">{formatDate(patient.dateOfBirth, 'MM/DD/YYYY')}</Text>
          </Text>
        )}
        <p className="header-callback">{getCallbackText()}</p>
      </div>
      <div className="call-logging-window-actions">
        {renderSaveDraftMenu ? (
          <SaveDraftMenu
            onSaveDraftClicked={onSaveDraftClicked}
            call={currentCall}
            isSaveInProgress={isSaveInProgress}
            isSummaryManuallyEdited={isSummaryManuallyEdited}
          />
        ) : (
          <SaveDraftButton
            onSaveDraftClicked={() => {
              trackActionButtonAnalyticsEvent({
                action: AnalyticEventAction.SaveDraft,
                virtual_page: 'call logger',
                summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
              });
              onSaveDraftClicked();
            }}
            isSaveInProgress={isSaveInProgress}
          />
        )}

        <StyledWrapper className="minimize-btn-wrapper">
          <i
            className="minimize-btn cursor-pointer"
            onClick={!minimized ? onToggleMinimizeClicked : null}
          />
        </StyledWrapper>

        <StyledIconButton
          testHook={patientPageTestSelectors.callLogger.deleteCallButton}
          onClick={onCloseClicked}
        >
          <Icon.XThick />
        </StyledIconButton>
      </div>
    </div>
  );
};

const StyledWrapper = styled('span')`
  display: inline-block;
  margin-left: 12px;
`;

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(16)};
    color: ${theme.palette.text.primary};

    svg {
      display: flex;
    }
  `
);

export const CallLoggingHeader = observer(Header);
