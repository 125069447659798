export enum ERROR_CODE {
  INVALID_PHONE = 100,
  PATIENT_DOES_NOT_EXIST = 101,
  DUPLICATE_PHONE = 102,
  DUPLICATE_MRN = 103,
  OVERLAPPING_INTERVALS = 104,
  INVALID_EMAIL = 105,
  INVALID_REQUEST = 106,
  DUPLICATION_ERROR = 107,
  DOCTOR_DOES_NOT_EXIST = 108,
  TICKET_DOUBLE_PICKUP = 109,
  DISABLE_NON_EMPTY_LOCATION = 110,
  RESOLVE_TICKETS_CONFLICT = 'RESOLVE_TICKETS_CONFLICT',
  RESOLVE_CLOSED_TICKET = 'RESOLVE_CLOSED_TICKET',
  DUPLICATE_EPISODE_NAME_ERROR_CODE = 800,
  INVALID_EPISODE_DURATION_ERROR = 801,
  DUPLICATE_EPISODE_END_REASON = 802,
  DUPLICATE_REASON = 803,
  ENROLL_IN_ACTIVE_CM = 113,
  THOUGHT_SPOT = 114,
  NOT_MOBILE_PHONE = 117,
  INTERVAL_OUT_OF_ALLOWED_SCOPE = 118,
  SSO_AUTHENTICATED_BUT_NOT_REGISTERED = 119,
  DUPLICATE_TICKET_SUB_TYPE_OPERATOR_NAME = 122,
  DUPLICATE_TICKET_TYPE_DISPLAY_NAME_FOR_PATIENT_APP = 123,
  TICKET_CONNECTED_TO_DRAFT = 124,
  THOUGHT_SPOT_NEW_USER = 125,
  BULK_INVITE_IN_PROGRESS = 127,
  SUMMARY_CHARACTER_LIMIT = 128,
  LAND_PHONE_ON_AUTO_PROTOCOL = 129,
  PATIENT_UNSUBSCRIBED_FROM_SMS = 130,
  FAILED_TO_SEND_SMS = 131,
  DUPLICATE_ROLE_NAME = 132,
  DUPLICATE_EMR_PATIENT_FOUND = 133,
  NO_DUPLICATE_EMR_PATIENT_FOUND = 134,
  RESOLVING_UNREAD_INBOUND_MESSAGES = 135,
  RESOLVING_FAILED_OUTBOUND_MESSAGES = 136,
  RESOLVING_PENDING_OUTBOUND_MESSAGES = 137,
  PATIENT_CANNOT_REPLY_AFTER_TICKET_RESOLVE = 138,
  MULTIPLE_DUPLICATE_EMR_PATIENT_FOUND = 139
}
